import { ZoneTypes } from "../models/zones/ZoneModel";

const shapeOptions = {
  operationsZone: {
    strokeColor: "#000",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    fillColor: "#607D8B",
    fillOpacity: 0.3,
    clickable: true,
    zIndex: 1,
  },
  preferredBikeReturnZone: {
    strokeColor: "#e9e623",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e9e623",
    fillOpacity: 0.5,
    clickable: true,
    zIndex: 2,
  },
  preferredBikeReturnZoneDisabled: {
    strokeColor: "#e95123",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#e95123",
    fillOpacity: 0.5,
    clickable: true,
    zIndex: 2,
  },
};

const getShapeOptions = (zone) => {
  if (zone.type === ZoneTypes.preferredBikeReturnZone.value) {
    if (zone.visibility) {
      return shapeOptions.preferredBikeReturnZone;
    } else {
      return shapeOptions.preferredBikeReturnZoneDisabled;
    }
  }
  return shapeOptions[zone.type];
};

export default getShapeOptions;
