import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ZoneTypes } from "../../models/zones/ZoneModel";
import ImportZoneDialog from "./ImportZoneDialog";

class ExistingZoneDetails extends Component {
  constructor() {
    super();
    this.state = {
      importZoneDialogVisible: false,
      importZoneDialogSubmitting: false,
    };
    this.handleOpenImportZoneDialog =
      this.handleOpenImportZoneDialog.bind(this);
    this.handleImportZone = this.handleImportZone.bind(this);
  }

  handleImportZone(data) {
    this.setState({ submitting: true });
    this.props
      .onImport(data.name, data.kmlFile.content)
      .finally(() =>
        this.setState({ submitting: false, importZoneDialogVisible: false }),
      );
  }

  handleOpenImportZoneDialog() {
    this.setState({ importZoneDialogVisible: true });
  }

  renderImportZoneDialog() {
    return (
      <ImportZoneDialog
        open={this.state.importZoneDialogVisible}
        onSave={this.handleImportZone}
        onCancel={() => this.setState({ importZoneDialogVisible: false })}
        submitting={this.state.importZoneDialogSubmitting}
      />
    );
  }

  render() {
    const { zone, onDelete, onAddMarkerLocation, onEdit, onToggleZone } =
      this.props;
    return (
      <div>
        <Typography variant="subtitle1" gutterBottom>
          {zone.type === ZoneTypes.operationsZone.value
            ? ZoneTypes.operationsZone.name
            : ZoneTypes.preferredBikeReturnZone.name}
          <strong>{zone.name}</strong>
        </Typography>
        {zone.areaCircle && (
          <div>Promień w metrach: {zone.areaCircle.radius}</div>
        )}
        <div className="actions">
          {zone.bikesReturnZoneTypeId && onAddMarkerLocation && (
            <Button color="primary" size="small" onClick={onAddMarkerLocation}>
              Ustal znacznik
            </Button>
          )}
          {onEdit && (
            <Button color="secondary" size="small" onClick={onEdit}>
              Edytuj obszar
            </Button>
          )}
          {onToggleZone &&
            zone.type === ZoneTypes.preferredBikeReturnZone.value && (
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  onToggleZone(!zone.visibility);
                }}
              >
                {zone.visibility ? "Wyłącz strefę" : "Włącz strefę"}
              </Button>
            )}
          {zone.type === ZoneTypes.operationsZone.value && (
            <Button
              color="secondary"
              size="small"
              onClick={this.handleOpenImportZoneDialog}
            >
              Importuj obszar
            </Button>
          )}
          <Button size="small" onClick={onDelete}>
            Usuń strefę
          </Button>
        </div>
        {this.renderImportZoneDialog()}
      </div>
    );
  }
}

ExistingZoneDetails.propTypes = {
  zone: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onToggleZone: PropTypes.func,
  onEdit: PropTypes.func,
  onImport: PropTypes.func,
  onAddMarkerLocation: PropTypes.func,
};

export default ExistingZoneDetails;
