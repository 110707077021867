import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import config from "../../../config";
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  Circle,
  Marker,
  Polyline,
} from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import { ZoneAreaTypes, ZoneTypes } from "../../../models/zones/ZoneModel";
import ReportsApi from "../../../api/reportsApi";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Paper,
  Grid,
  Button,
  LinearProgress,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import "./Heatmap.scss";
import getShapeOptions from "../../../utils/getZoneShapeOptions";

const startPointsGradient = [
  "rgba(0, 255, 255, 0)",
  "rgba(0, 255, 255, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(0, 127, 255, 1)",
  "rgba(0, 63, 255, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 223, 1)",
  "rgba(0, 0, 191, 1)",
  "rgba(0, 0, 159, 1)",
  "rgba(0, 0, 127, 1)",
  "rgba(63, 0, 91, 1)",
  "rgba(127, 0, 63, 1)",
  "rgba(191, 0, 31, 1)",
  "rgba(255, 0, 0, 1)",
];

const finishPointsGradient = [
  "rgba(102, 255, 0, 0)",
  "rgba(102, 255, 0, 1)",
  "rgba(147, 255, 0, 1)",
  "rgba(193, 255, 0, 1)",
  "rgba(238, 255, 0, 1)",
  "rgba(244, 227, 0, 1)",
  "rgba(249, 198, 0, 1)",
  "rgba(255, 170, 0, 1)",
  "rgba(255, 113, 0, 1)",
  "rgba(255, 57, 0, 1)",
  "rgba(255, 0, 0, 1)",
];

const getMarkerIcon = (zoneIconUrl) => {
  return {
    url: zoneIconUrl,
    /* eslint-disable no-undef */
    scaledSize: new google.maps.Size(32, 32),
  };
};

const MapView = withScriptjs(
  withGoogleMap((props) => {
    const mapOptions = {
      streetViewControl: false,
      mapTypeControl: true,
      /* eslint-disable no-undef */
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      clickableIcons: false,
      scaleControl: true,
    };
    return (
      <GoogleMap
        ref={props.onMapLoad}
        defaultZoom={props.initialZoom}
        defaultCenter={props.initialLocation}
        center={props.initialLocation}
        defaultOptions={mapOptions}
      >
        {props.showOperationsZone &&
          props.zones
            .filter(
              (zone) =>
                zone.type === ZoneTypes.operationsZone.value &&
                zone.areaType === ZoneAreaTypes.polygon,
            )
            .map((zone) => (
              <Polygon
                key={zone.id}
                path={zone.area}
                options={getShapeOptions(zone)}
              />
            ))}
        {props.showOperationsZone &&
          props.zones
            .filter(
              (zone) =>
                zone.type === ZoneTypes.operationsZone.value &&
                zone.areaType === ZoneAreaTypes.circle,
            )
            .map((zone) => (
              <Circle
                key={zone.id}
                center={zone.area.center}
                radius={zone.area.radius}
                options={getShapeOptions(zone)}
              />
            ))}

        {props.showZones &&
          props.zones
            .filter(
              (zone) =>
                zone.type !== ZoneTypes.operationsZone.value &&
                zone.areaType === ZoneAreaTypes.polygon,
            )
            .map((zone) => (
              <Polygon
                key={zone.id}
                zIndex={0}
                path={zone.area}
                options={getShapeOptions(zone)}
              />
            ))}
        {props.showZones &&
          props.zones
            .filter(
              (zone) =>
                zone.type !== ZoneTypes.operationsZone.value &&
                zone.areaType === ZoneAreaTypes.circle,
            )
            .map((zone) => (
              <Circle
                key={zone.id}
                center={zone.area.center}
                radius={zone.area.radius}
                options={getShapeOptions(zone)}
              />
            ))}
        {props.showZones &&
          props.zones
            .filter((z) => z.marker)
            .map((zone) => (
              <Marker
                key={`marker${zone.id}`}
                icon={zone.marker.icon ? getMarkerIcon(zone.marker.icon) : null}
                position={zone.marker}
              />
            ))}

        {props.showConnections &&
          props.heatmapData.map((row, i) => (
            <Polyline
              key={`line${i}`}
              geodesic={true}
              options={{ strokeColor: "#4d4d4d", strokeWeight: 0.5 }}
              path={[
                { lat: row[0], lng: row[1] },
                { lat: row[2], lng: row[3] },
              ]}
            />
          ))}

        {props.showStartLocations && (
          <HeatmapLayer
            data={props.heatmapData.map(
              (row) => new google.maps.LatLng(row[0], row[1]),
            )}
            options={{
              gradient: startPointsGradient,
              dissipating: true,
              maxIntensity: props.maxIntensity,
            }}
          />
        )}

        {props.showFinishLocations && (
          <HeatmapLayer
            data={props.heatmapData.map(
              (row) => new google.maps.LatLng(row[2], row[3]),
            )}
            options={{
              gradient: finishPointsGradient,
              dissipating: true,
              maxIntensity: props.maxIntensity,
            }}
          />
        )}

        {props.showTraces && (
          <HeatmapLayer
            data={props.heatmapTracesData.map(
              (row) => new google.maps.LatLng(row[0], row[1]),
            )}
            options={{
              gradient: finishPointsGradient,
              dissipating: true,
              maxIntensity: props.maxIntensity,
            }}
          />
        )}
      </GoogleMap>
    );
  }),
);

class Heatmap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.state = {
      heatmapData: [],
      heatmapTracesData: [],
      showStartLocations: true,
      showFinishLocations: false,
      showOperationsZone: true,
      showZones: false,
      showTraces: false,
      showConnections: false,
      maxIntensity: null,
      loading: true,
      loadingTrace: true,
    };
    this.handleRefreshData = this.handleRefreshData.bind(this);
    this.handleRefreshTracesData = this.handleRefreshTracesData.bind(this);
    this.handleChangeSetting = this.handleChangeSetting.bind(this);
    this.handleChangeIntensity = this.handleChangeIntensity.bind(this);
  }

  componentDidMount() {
    this.handleRefreshData(this.props.dataFilter);
    this.handleRefreshTracesData(this.props.dataFilter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleRefreshData(nextProps.dataFilter);
    this.handleRefreshTracesData(this.props.dataFilter);
  }

  handleRefreshData(dataFilter) {
    this.setState({ loading: true });
    ReportsApi.getHeatmapData(dataFilter)
      .then((response) => {
        this.setState({ heatmapData: response.data });
      })
      .finally(() => this.setState({ loading: false }));
  }

  handleRefreshTracesData(dataFilter) {
    this.setState({ loadingTrace: true });
    ReportsApi.getHeatmapTracesData(dataFilter)
      .then((response) => {
        this.setState({ heatmapTracesData: response.data });
      })
      .finally(() => this.setState({ loadingTrace: false }));
  }

  handleChangeSetting(name) {
    return (event) => {
      this.setState({ ...this.state, [name]: event.target.checked });
    };
  }

  handleChangeIntensity(event, value) {
    this.setState({ maxIntensity: value });
  }

  render() {
    const { initialLocation, zones } = this.props;

    if (this.state.loading || this.state.loadingTrace)
      return (
        <Paper style={{ padding: 16 }}>
          <Typography align="center" variant="subtitle1">
            Wczytywanie danych
          </Typography>
          <LinearProgress />
        </Paper>
      );

    return (
      <Paper>
        <MapView
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}&libraries=visualization,geometry`}
          onMapLoad={(mapRef) => (this.map = mapRef)}
          loadingElement={<div style={{ height: "100%", width: 600 }} />}
          containerElement={<div style={{ width: "100%", height: 700 }} />}
          mapElement={<div style={{ width: "100%", height: 700 }} />}
          initialZoom={12}
          initialLocation={initialLocation}
          heatmapData={this.state.heatmapData}
          heatmapTracesData={this.state.heatmapTracesData}
          showStartLocations={this.state.showStartLocations}
          showFinishLocations={this.state.showFinishLocations}
          showConnections={this.state.showConnections}
          showOperationsZone={this.state.showOperationsZone}
          showZones={this.state.showZones}
          showTraces={this.state.showTraces}
          maxIntensity={this.state.maxIntensity}
          zones={zones}
        />
        <div style={{ padding: 16 }}>
          <Typography variant="body2">
            Liczba wizualizowanych przejazdów: {this.state.heatmapData.length}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showStartLocations}
                  onChange={this.handleChangeSetting("showStartLocations")}
                  value="showStartLocations"
                />
              }
              label="Miejsca rozpoczęcia przejazdów"
            />
            {this.state.showStartLocations && (
              <div id="started-heatmap-legend" />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showFinishLocations}
                  onChange={this.handleChangeSetting("showFinishLocations")}
                  value="showFinishLocations"
                />
              }
              label="Miejsca zakończenia przejazdów"
            />
            {this.state.showTraces && <div id="finished-heatmap-legend" />}
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showTraces}
                  onChange={this.handleChangeSetting("showTraces")}
                  value="showTraces"
                />
              }
              label="Trasa"
            />
            {this.state.showTraces && <div id="trace-heatmap-legend" />}
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showConnections}
                  onChange={this.handleChangeSetting("showConnections")}
                  value="showConnections"
                />
              }
              label="Wyświetlaj połączenia"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showOperationsZone}
                  onChange={this.handleChangeSetting("showOperationsZone")}
                  value="showOperationsZone"
                />
              }
              label="Wyświetlaj obszar działania"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showZones}
                  onChange={this.handleChangeSetting("showZones")}
                  value="showZones"
                />
              }
              label="Wyświetlaj strefy zwrotu"
            />
            <Typography variant="caption" id="slider" gutterBottom>
              Próg maksymalnej intensywności koloru - minimalna liczba
              przejazdów zaliczana jako maksymalny próg temperaturowy mapy
              ciepła:{" "}
              <strong>
                {this.state.maxIntensity ? this.state.maxIntensity : "automat"}
              </strong>
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Button onClick={() => this.setState({ maxIntensity: null })}>
                  Domyślne
                </Button>
              </Grid>
              <Grid item>
                <Typography>1</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  id="slider"
                  min={1}
                  marks
                  max={this.state.heatmapData.length || 100}
                  value={this.state.maxIntensity}
                  onChange={this.handleChangeIntensity}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
              <Grid item>
                <Typography>{this.state.heatmapData.length}</Typography>
              </Grid>
            </Grid>
          </FormGroup>
        </div>
      </Paper>
    );
  }
}

Heatmap.propTypes = {
  dataFilter: PropTypes.array,
  zones: PropTypes.array.isRequired,
  initialLocation: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    zones: state.zones,
    initialLocation: state.configuration.initialLocation,
  };
}

export default connect(mapStateToProps)(Heatmap);
