import React from "react";
import PropTypes from "prop-types";
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker,
  Circle,
} from "react-google-maps";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import bikeAvailableIcon from "../../../assets/bikeAvailableIcon.png";
import config from "../../../config";
import _ from "lodash";
import { ZoneAreaTypes } from "../../../models/zones/ZoneModel";
import getShapeOptions from "../../../utils/getZoneShapeOptions";

const getBoundingBox = (trace) =>
  trace &&
  trace.length && {
    north: _.maxBy(trace, (t) => t.lat),
    south: _.minBy(trace, (t) => t.lat),
    east: _.maxBy(trace, (t) => t.lng),
    west: _.minBy(trace, (t) => t.lng),
  };

const getCenter = (boundingBox) =>
  boundingBox && {
    lat:
      boundingBox.south.lat +
      (boundingBox.north.lat - boundingBox.south.lat) / 2,
    lng:
      boundingBox.west.lng + (boundingBox.east.lng - boundingBox.west.lng) / 2,
  };

const latRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const getZoom = (boundingBox) => {
  if (!boundingBox) return null;
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  const pixelWidth = 400;

  const fraction =
    (latRad(boundingBox.north.latitude) - latRad(boundingBox.south.latitude)) /
    Math.PI;
  const LN2 = 0.693147180559945309417;
  const zoom = Math.log(pixelWidth / GLOBE_WIDTH / fraction) / LN2;
  return _.min([zoom - 0.2, 18]);
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: true,
  clickableIcons: false,
};
const MapViewRaw = withScriptjs(
  withGoogleMap((props) => {
    const boundingBox = getBoundingBox(props.marker ? [props.marker] : []);
    return (
      <GoogleMap
        ref={props.onMapLoad}
        zoom={getZoom(boundingBox)}
        center={getCenter(boundingBox)}
        onClick={props.onMapClick}
        defaultOptions={mapOptions}
      >
        {props.marker && (
          <Marker
            position={{ lat: props.marker.lat, lng: props.marker.lng }}
            title="Pozycja zakończenia jazdy"
            icon={bikeAvailableIcon}
          />
        )}
        {props.zones
          ? props.zones
              .filter((zone) => zone.areaType === ZoneAreaTypes.polygon)
              .map((zone) => (
                <Polygon
                  key={zone.id}
                  path={zone.area}
                  options={getShapeOptions(zone)}
                />
              ))
          : null}
        {props.zones
          ? props.zones
              .filter((zone) => zone.areaType === ZoneAreaTypes.circle)
              .map((zone) => (
                <Circle
                  key={zone.id}
                  center={zone.area.center}
                  radius={zone.area.radius}
                  options={getShapeOptions(zone)}
                />
              ))
          : null}
      </GoogleMap>
    );
  }),
);

const FormMapView = (props) => (
  <MapViewRaw
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`}
    {...props}
  />
);

FormMapView.propTypes = {
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  marker: PropTypes.object,
  zones: PropTypes.array,
};

export default FormMapView;
