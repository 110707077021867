import React from "react";
import PropTypes from "prop-types";
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  Polyline,
  Marker,
  Circle,
} from "react-google-maps";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import red from "@material-ui/core/colors/red";
import config from "../../../config";
import _ from "lodash";
import { ZoneAreaTypes } from "../../../models/zones/ZoneModel";
import getShapeOptions from "../../../utils/getZoneShapeOptions";

export const userLocationIcon = {
  path: "M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zM18 16H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z",
  fillColor: red[700],
  fillOpacity: 1,
  strokeWeight: 0,
  anchor: { x: 12, y: 24 },
};

const getBoundingBox = (trace) =>
  trace &&
  trace.length && {
    north: _.maxBy(trace, (t) => t.lat),
    south: _.minBy(trace, (t) => t.lat),
    east: _.maxBy(trace, (t) => t.lng),
    west: _.minBy(trace, (t) => t.lng),
  };

const getCenter = (boundingBox) =>
  boundingBox && {
    lat:
      boundingBox.south.lat +
      (boundingBox.north.lat - boundingBox.south.lat) / 2,
    lng:
      boundingBox.west.lng + (boundingBox.east.lng - boundingBox.west.lng) / 2,
  };

const latRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const getZoom = (boundingBox) => {
  if (!boundingBox) return null;
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  const pixelWidth = 400;

  const fraction =
    (latRad(boundingBox.north.latitude) - latRad(boundingBox.south.latitude)) /
    Math.PI;
  const LN2 = 0.693147180559945309417;
  const zoom = Math.log(pixelWidth / GLOBE_WIDTH / fraction) / LN2;
  return _.min([zoom - 0.2, 18]);
};

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: true,
  clickableIcons: false,
};
const MapViewRaw = withScriptjs(
  withGoogleMap((props) => {
    const boundingBox = getBoundingBox(
      props.trace && props.trace.length
        ? props.trace
        : props.attemptLocation
          ? [props.attemptLocation]
          : [],
    );
    return (
      <GoogleMap
        ref={props.onMapLoad}
        zoom={getZoom(boundingBox)}
        center={getCenter(boundingBox)}
        onClick={props.onMapClick}
        defaultOptions={mapOptions}
      >
        {props.markers
          ? props.markers.map((marker, key) =>
              props.markerElement({ ...marker, key }),
            )
          : null}
        {props.zones
          ? props.zones
              .filter((zone) => zone.areaType === ZoneAreaTypes.polygon)
              .map((zone) => (
                <Polygon
                  key={zone.id}
                  path={zone.area}
                  options={getShapeOptions(zone)}
                />
              ))
          : null}
        {props.zones
          ? props.zones
              .filter((zone) => zone.areaType === ZoneAreaTypes.circle)
              .map((zone) => (
                <Circle
                  key={zone.id}
                  center={zone.area.center}
                  radius={zone.area.radius}
                  options={getShapeOptions(zone)}
                />
              ))
          : null}
        {props.trace ? (
          <Polyline
            path={props.trace}
            visible
            options={{ strokeColor: "#4d4d4d" }}
          />
        ) : null}
        {props.attemptLocation && (
          <Marker
            icon={userLocationIcon}
            position={{
              lat: props.attemptLocation.lat,
              lng: props.attemptLocation.lng,
            }}
            title="Pozycja użytkownika podczas rozpoczęcia wypożyczenia"
          />
        )}
      </GoogleMap>
    );
  }),
);

const MapTraceView = (props) => (
  <MapViewRaw
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${config.GOOGLE_MAPS_API_KEY}`}
    {...props}
  />
);

MapTraceView.propTypes = {
  markerElement: PropTypes.func.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  markers: PropTypes.array,
  zones: PropTypes.array,
  trace: PropTypes.array,
  attemptLocation: PropTypes.object,
};

export default MapTraceView;
