import zonesApi from "../api/zonesApi";
import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";
import { ZoneTypes } from "../models/zones/ZoneModel";

export const types = {
  LOAD_ZONES_SUBMIT: "LOAD_ZONES_SUBMIT",
  LOAD_ZONES_SUCCESS: "LOAD_ZONES_SUCCESS",
  LOAD_ZONES_FAILURE: "LOAD_ZONES_FAILURE",
  CREATE_ZONE_SUBMIT: "CREATE_ZONE_SUBMIT",
  CREATE_ZONE_SUCCESS: "CREATE_ZONE_SUCCESS",
  CREATE_ZONE_FAILURE: "CREATE_ZONE_FAILURE",
  IMPORT_ZONE_SUBMIT: "IMPORT_ZONE_SUBMIT",
  IMPORT_ZONE_SUCCESS: "IMPORT_ZONE_SUCCESS",
  IMPORT_ZONE_FAILURE: "IMPORT_ZONE_FAILURE",
  DELETE_ZONE_SUBMIT: "DELETE_ZONE_SUBMIT",
  DELETE_ZONE_SUCCESS: "DELETE_ZONE_SUCCESS",
  DELETE_ZONE_FAILURE: "DELETE_ZONE_FAILURE",
  UPDATE_ZONE_MARKER_SUBMIT: "UPDATE_ZONE_MARKER_SUBMIT",
  UPDATE_ZONE_MARKER_SUCCESS: "UPDATE_ZONE_MARKER_SUCCESS",
  UPDATE_ZONE_MARKER_FAILURE: "UPDATE_ZONE_MARKER_FAILURE",
  UPDATE_ZONE_AREA_SUBMIT: "UPDATE_ZONE_AREA_SUBMIT",
  UPDATE_ZONE_AREA_SUCCESS: "UPDATE_ZONE_AREA_SUCCESS",
  UPDATE_ZONE_AREA_FAILURE: "UPDATE_ZONE_AREA_FAILURE",
  CHANGE_VISIBILITY_ZONE_SUBMIT: "CHANGE_VISIBILITY_ZONE_SUBMIT",
  CHANGE_VISIBILITY_ZONE_SUCCESS: "CHANGE_VISIBILITY_ZONE_SUCCESS",
  CHANGE_VISIBILITY_ZONE_FAILURE: "CHANGE_VISIBILITY_ZONE_FAILURE",
};

export const actions = {
  load: () => (dispatch) => {
    dispatch({ type: types.LOAD_ZONES_SUBMIT });

    return zonesApi.getAll().then((response) => {
      if (response.ok) {
        return dispatch({
          type: types.LOAD_ZONES_SUCCESS,
          zones: response.data,
        });
      } else {
        dispatch({ type: types.LOAD_ZONES_FAILURE });
        dispatch(
          notificationActions.showError(
            "Nie udało się załadować informacji o strefach operatora",
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  save: (zone) => (dispatch) => {
    dispatch({ type: types.CREATE_ZONE_SUBMIT });

    const apiCall =
      zone.type === ZoneTypes.operationsZone.value
        ? zonesApi.createBikeRentalOperationsZone(zone)
        : zonesApi.createPreferredBikeReturnZone(zone);

    return apiCall.then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.CREATE_ZONE_SUCCESS,
          zone: Object.assign({}, zone, { id: response.data }),
        });
        dispatch(notificationActions.showSuccess("Zapisano strefę"));
      } else {
        dispatch({ type: types.CREATE_ZONE_FAILURE });
        dispatch(
          notificationActions.showError(
            `Zapis strefy nie powiódł się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  import: (name, kmlFile) => (dispatch) => {
    dispatch({ type: types.IMPORT_ZONE_SUBMIT });
    return zonesApi
      .importBikeRentalOperationsZone(name, kmlFile)
      .then((response) => {
        if (response.ok && response.data.success !== false) {
          dispatch({ type: types.IMPORT_ZONE_SUCCESS, zone: response.data });
          dispatch(notificationActions.showSuccess("Zaimportowano strefę"));
        } else {
          dispatch({ type: types.IMPORT_ZONE_FAILURE });
          dispatch(
            notificationActions.showError(
              `Import strefy nie powiódł się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
            ),
          );

          return logger.error(response.data);
        }
      });
  },
  delete: (id) => (dispatch) => {
    dispatch({ type: types.DELETE_ZONE_SUBMIT });
    return zonesApi.delete(id).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.DELETE_ZONE_SUCCESS, id });
        dispatch(notificationActions.showSuccess("Usunięto strefę"));
      } else {
        dispatch({ type: types.DELETE_ZONE_FAILURE });
        dispatch(
          notificationActions.showError(
            `Usunięcie strefy nie powiodło się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  changeVisibility: (id, visibility) => (dispatch) => {
    dispatch({ type: types.CHANGE_VISIBILITY_ZONE_SUBMIT });
    return zonesApi.changeVisibility(id, visibility).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.CHANGE_VISIBILITY_ZONE_SUCCESS,
          id,
          visibility,
        });
        dispatch(
          notificationActions.showSuccess("Zmieniono widoczność strefy"),
        );
      } else {
        dispatch({ type: types.CHANGE_VISIBILITY_ZONE_FAILURE });
        dispatch(
          notificationActions.showError(
            `Zmiana widoczności strefy nie powiodło się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  setMarkerForZone: (id, marker) => (dispatch) => {
    dispatch({ type: types.UPDATE_ZONE_MARKER_SUBMIT });
    return zonesApi.setMarkerForZone(id, marker).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({ type: types.UPDATE_ZONE_MARKER_SUCCESS, id, marker });
        dispatch(
          notificationActions.showSuccess("Zapisano znacznik dla strefy"),
        );
      } else {
        dispatch({ type: types.UPDATE_ZONE_MARKER_FAILURE });
        dispatch(
          notificationActions.showError(
            `Zapis znacznika nie powiódł się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
          ),
        );

        return logger.error(response.data);
      }
    });
  },
  updateArea: (zone) => (dispatch) => {
    dispatch({ type: types.UPDATE_ZONE_AREA_SUBMIT });
    return zonesApi.updateArea(zone).then((response) => {
      if (response.ok && response.data.success !== false) {
        dispatch({
          type: types.UPDATE_ZONE_AREA_SUCCESS,
          id: zone.id,
          area: zone.area,
        });
        dispatch(
          notificationActions.showSuccess("Zaktualizowano obszar strefy"),
        );
      } else {
        dispatch({ type: types.UPDATE_ZONE_AREA_FAILURE });
        dispatch(
          notificationActions.showError(
            `Zapis obszaru nie powiódł się${response.data && response.data.failure ? ": " + response.data.failure.message : ""}`,
          ),
        );

        return logger.error(response.data);
      }
    });
  },
};
