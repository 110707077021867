import React from "react";
import PropTypes from "prop-types";
import ZoneDetailsForm from "./ZoneDetailsForm";
import ExistingZoneDetails from "./ExistingZoneDetails";

const ZoneDetails = ({
  zone,
  onSave,
  onDelete,
  onEdit,
  onImport,
  onToggleZone,
  onAddMarkerLocation,
}) => (
  <div style={{ marginBottom: 16 }}>
    {zone.id ? (
      <ExistingZoneDetails
        onToggleZone={onToggleZone}
        zone={zone}
        onDelete={onDelete}
        onEdit={onEdit}
        onImport={onImport}
        onAddMarkerLocation={onAddMarkerLocation}
      />
    ) : (
      <ZoneDetailsForm
        onSubmit={(data) => onSave(data)}
        zoneType={zone.type}
        radius={zone.area.radius}
        areaType={zone.areaType}
      />
    )}
  </div>
);

ZoneDetails.propTypes = {
  zone: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onAddMarkerLocation: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onToggleZone: PropTypes.func,
  onEdit: PropTypes.func,
  onImport: PropTypes.func,
};

export default ZoneDetails;
